<template>
  <div>
    <v-card :height="height" class="ma-2 d-flex align-center justify-center">
      <span> Under development </span>
    </v-card>
  </div>
</template>
<script>
export default {
  data () {
    return {
      height: window.innerHeight - 78
    }
  },
  mounted () {
    window.onresize = () => {
      this.height = window.innerHeight - 78
    }
  }
}
</script>
